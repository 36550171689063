<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="pt-0 px-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold text-center">
                  Create New Administrative Case
                </h3>
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <h2>Product Information</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="selectedCompany"
                    v-model="selectedCompany.company_name"
                    outlined
                    light
                    dense
                    hide-details
                    label="Company"
                    class="px-1 pt-0"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-if="selectedProject"
                    v-model="selectedProject.contract_name"
                    outlined
                    light
                    dense
                    hide-details
                    label="Project"
                    class="px-1 pt-0"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedPriority"
                    outlined
                    light
                    dense
                    hide-details
                    label="Priority"
                    :items="priorityList"
                    class="px-1 pt-0"
                  ></v-select>
                </v-col>
                <v-col col="12">
                  <v-select
                    :items="problemAreaList"
                    v-model="case_frm.case_problem_area"
                    outlined
                    light
                    dense
                    hide-details
                    label="Problem Area"
                    class="px-1 pt-0"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Case Information</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    v-model="case_frm.case_subject"
                    @input="changeSubject"
                    label="Subject"
                    class="px-1 pt-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <h4>Description</h4>
                  <tiptap-vuetify
                    v-model="case_frm.case_description"
                    :extensions="extensions"
                    placeholder="문제에 대한 자세한 설명을 기입해 주시기 바랍니다."
                    :disableInputRules="true"
                    :disablePasteRules="true"
                  />
                </v-col>

                <v-col cols="12">
                  <Attachments v-model="case_frm.attachments" />
                </v-col>

                <v-col cols="auto" class="ml-auto">
                  <v-btn block color="#FF4D00" dark @click="proceedCase">
                    PROCEED &nbsp;
                    <v-icon> mdi-arrow-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-dialog v-model="previewModel" max-width="1000px">
      <Preview
        v-if="previewModel"
        :case_frm="case_frm"
        :on_proceed="createCase"
        :on_close="modalBackBtn"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Preview from "@/components/Views/NewCase/Preview";
import Attachments from "@/components/Views/NewCase/Attachments";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "@jwmsg/tiptap-vuetify";
import { getProductByContract } from "@/api/Product";
import { getPriority } from "@/api/Presets";
import { newCase, getProductVersionByContract } from "@/api/Case";
export default {
  components: { TiptapVuetify, Preview, Attachments },
  beforeMount() {
    if (this.selectedCompany == null || this.selectedProject == null) {
      alert("잘못된 접근입니다. 정상적인 방법으로 접근해 주시기 바랍니다.");
      this.$router.push("/");
    }
    this.autoSelect();
  },
  computed: {
    selectedCompany() {
      return this.$store.getters["mainDash/selectedCompany"];
    },
    selectedProject() {
      return this.$store.getters["mainDash/selectedContract"];
    },
  },
  watch: {
    selectedProduct() {
      this.getProductVersionByContract();
    },
  },
  methods: {
    clearVersion() {
      this.case_frm.case_product_version == null;
    },
    changeSubject(str) {
      const total_str = str;
      if (this.searchEvtTimerIdx != null) {
        clearTimeout(this.searchEvtTimerIdx);
        this.searchEvtTimerIdx = null;
      }
      this.searchEvtTimerIdx = setTimeout(() => {
        console.log(total_str);
      }, 1000);
    },
    async autoSelect() {
      await this.getPriority();
      await this.getMyProduct();
      this.selectedPriority = this.priorityList[2].value;
    },
    async getMyProduct() {
      if (this.selectedProject == null) return;
      const data = await getProductByContract(
        this.selectedProject.contract_uuid
      );
      if (data.success) {
        const list = data.data.map((e) => ({
          text: e.TB_INFO_PRODUCT_LIST.product_name,
          value: e.TB_INFO_PRODUCT_LIST,
        }));
        this.productList = list;
      }
    },
    async getPriority() {
      const data = await getPriority();
      if (data.success) {
        const list = data.data.map((e) => ({
          text: e.level_name,
          value: e,
        }));
        this.priorityList = list;
      }
    },
    async getProductVersionByContract() {
      if (this.selectedProject == null || this.selectedProduct == null) return;
      const data = await getProductVersionByContract(
        this.selectedProject.contract_uuid,
        this.selectedProduct.product_uuid
      );
      this.versionList = data.data.map((e) => ({
        text: "[O] " + e.version,
        value: e.version,
      }));
      console.log(this.versionList);
    },
    inputChecker() {
      if (this.selectedProject == null) {
        alert("회사를 선택하십시오.");
        return false;
      }
      if (this.selectedProject == null) {
        alert("프로젝트(계약)를 선택하십시오.");
        return false;
      }
      if (
        this.case_frm.case_problem_area == null ||
        this.case_frm.case_problem_area == ""
      ) {
        alert("Rroblem Area 를 입력하십시오");
        return false;
      }

      if (
        this.case_frm.case_subject == null ||
        this.case_frm.case_subject == ""
      ) {
        alert("제목을 입력하십시오.");
        return false;
      }
      if (
        this.case_frm.case_description == null ||
        this.case_frm.case_description == ""
      ) {
        alert("설명을 입력하십시오.");
        return false;
      }
      return true;
    },
    async proceedCase() {
      if (!this.inputChecker()) return;
      this.case_frm.case_company_name = this.selectedCompany.company_name;
      this.case_frm.case_company_uuid = this.selectedCompany.company_uuid;
      this.case_frm.contract_uuid = this.selectedProject.contract_uuid;
      this.case_frm.contract_name = this.selectedProject.contract_name;
      this.case_frm.case_priority = this.selectedPriority.priority_level;
      this.case_frm.case_priority_name = this.selectedPriority.level_name;
      this.previewModel = true;
    },
    async modalBackBtn() {
      this.previewModel = false;
    },
    async createCase() {
      if (this.isProceed == true) {
        return alert("진행중입니다. 잠시만 기다려 주세요..");
      }
      this.isProceed = true;
      const response = await newCase(this.case_frm);
      console.log(response);
      if (response.success) {
        this.$router.push(`/case/${response.data.case_uuid}`);
        this.previewModel = false;
      } else {
        this.isProceed = false;
        alert(
          `케이스 등록에 문제가 발생하였습니다.\n${JSON.stringify(
            response.message
          )}`
        );
      }
    },
  },
  data() {
    return {
      searchEvtTimerIdx: null,
      productList: [],
      priorityList: [],
      problemAreaList: [
        "Accessing Online Support",
        "Support Account Contact Change",
        "Other (Non-Technical Only)",
        "End of Support",
        "Entitlements",
      ],
      selectedProduct: null,
      selectedPriority: null,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      description: "",
      errorlog: "",
      case_frm: {
        case_company_uuid: null,
        contract_uuid: null,
        case_product_uuid: null,
        case_product_version: null,
        case_priority: null,
        case_type: "Administrative",
        case_subject: null,
        case_description: null,
        case_errorlog: null,
        attachments: [],
      },
      selectedFile: null,
      previewModel: false,
      isProceed: false,
    };
  },
};
</script>

<style>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 250px;
  max-height: 250px;
}
</style>
